<template>
    <div class="deposit-container main-bg-light row w-100 mx-0 py-5">

        <div class="col-11 px-0 mx-auto pt-1 pt-md-5 pt-lg-3 pt-xl-0">
            <h2 class="text-white mb-3">Касса</h2>
            <h6 class="text-white mb-4">Пополни баланс и выиграй</h6>

            <div class="deposit-methods-container main-bg row mx-0 p-3 py-lg-5">
                <h6 class="text-white">Выберите способ пополнения</h6>
                <div class="row w-100 mx-0 justify-content-center justify-content-lg-between" v-if="depositMethods">
                    <div class="col-12 col-sm-12 col-md-6 col-xl-3 mt-2 mt-lg-3  px-lg-2"
                         v-for="method in depositMethods" :key="method.id"
                         v-lazy-container="{ selector: 'img',  loading: require('@/../public/images/icons/game-images-loader.svg') }">

                        <div class="deposit-method text-center" :class="[method.id == selectedMethod.id ? 'selected' : '']">

                            <img :src="getPictureUrl(method.picture)" :data-src="getPictureUrl(method.picture)"
                                 v-on:click="selectMethod(method)" :alt="method.name"
                                 class="deposit-method-image cursor-pointer mw-100 mh-100 px-3 px-md-1">
                            <div  class="min-amount-label">
                                <span class="control-label">
                                    Мин. {{ method.min_amount }} руб. <br />
                                    Макс. {{ method.max_amount }} руб. <br />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row justify-content-center my-3 w-100">
                    <i class="fa fa-spinner fa-spin fa-fw my-5 text-white"></i>
                </div>
            </div>

            <div class="make-deposit-container main-bg row mx-0 p-3 py-lg-5 mt-4" v-if="userData">
                <div class="row mx-0">
                    <div class="clearfix"></div>
                    <Alert :message="formResponse"></Alert>
                </div>

                <b-form class="deposit-form payment-form w-100 px-1 px-lg-3" @submit="payInFormSubmit" method="POST"
                        id="deposit-form" :action="$_config.payInUrl">
                    <div class="row w-100 mx-0">
                        <div class="col-12 col-md-8 col-lg-4 mx-auto px-0 px-lg-2">
                            <input class="form-control" v-model="sum"
                                   type="number" required id="depositAmount" ref="depositAmount"
                                   name="payin_amount" aria-label="deposit-amount"
                                   autocomplete="off" placeholder="Сумма в рублях *">
                            <input type="hidden" v-model="selectedMethod.id" name="method">
                            <input type="hidden" v-model="selectedMethod.module" name="selected_system">
                        </div>

                        <div class="col-12 col-md-8 col-lg-4 mx-auto my-3 my-lg-0 px-0 px-lg-2">
                            <button class="btn red-btn h-100 w-100 py-3 py-xl-0" type="submit">
                                Пополнить
                            </button>
                        </div>

                        <div class="col-12 col-md-8 col-lg-4 mx-auto px-0 px-lg-2">
                            <button @click.prevent="$root.$emit('bv::show::modal','ActivatePromocode')"
                                    class="btn promo-btn h-100 w-100 py-3">
                                Есть промо-код?
                            </button>
                        </div>
                    </div>
                </b-form>

                <div class="row w-100 mx-0 mt-4 mt-lg-5 main-bg-light p-1">
                    <div class="col-12 col-md-6 py-2">
                        <span class="hint-text">К оплате:</span>
                        <span class="text-white fs-14">
                            {{sum ? sum : 0}} Руб
                            {{selectedMethod.name ? ', через : ' + selectedMethod.name  : ''}}
                        </span>
                    </div>
                    <div class="col-12 col-md-6 text-left text-md-right">
                        <span class="hint-text fs-11">
                            Минимальное пополнение за раз
                            {{selectedMethod.min_amount ? ', через : ' + selectedMethod.min_amount  : ''}} руб.<br>
                            Максимальное пополнение за раз 15000 руб.
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <activate-promocode></activate-promocode>
    </div>
</template>

<script>
    import ActivatePromocode from '@/components/payment/ActivatePromocode'
    import Alert from '@/components/Alert'

    export default {
        name: 'Deposit',
        data() {
            return {
                sum: '',
                selectedMethod: {
                    id: null,
                    module: '',
                    name: '',
                    min_amount: 50
                },
                formResponse: null
            }
        },
        components: {
            Alert,
            ActivatePromocode
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            },
            depositMethods() {
                return this.$store.getters['getAvilDepositMethods']
            }
        },
        // mounted() {
        //     this.$store.dispatch('fetchAvilDepositMethods')
        // },
        methods: {
            getPictureUrl(url) {
                return this.$_config.baseUrl + url
            },
            selectMethod(selected) {
                if (!this.userData) {
                    this.selectedMethod.id = null
                    this.selectedMethod.module = ''
                    this.selectedMethod.name = ''
                    this.selectedMethod.min_amount = 60
                    this.$root.$emit('bv::show::modal', 'login-modal')
                    return

                }
                this.selectedMethod.name = selected.name
                this.selectedMethod.min_amount = selected.min_amount
                this.selectedMethod.id = selected.id
                this.selectedMethod.module = selected.method_module

                setTimeout(() => {
                    if (document.getElementById("depositAmount") !== null) {
                        document.getElementById("depositAmount").focus();
                    }
                }, 500)

            },
            payInFormSubmit(e) {
                if (!this.userData) {
                    e.preventDefault()
                    this.formResponse = {status: 'error', msg: "Unauthorized"}
                    setTimeout(() => {
                        this.$root.$emit('bv::show::modal', 'login-modal')
                    }, 500)
                    return
                }

                let payinAmount = parseFloat(this.$refs.depositAmount.value) || 0
                if (!payinAmount > 0) {
                    e.preventDefault()
                    this.formResponse = {status: 'error', msg: "Пожалуйста, укажите правильую сумму"}
                    return false
                }
                if (!this.selectedMethod.id || !this.selectedMethod.module) {
                    e.preventDefault()
                    this.formResponse = {status: 'error', msg: "Пожалуйста выберите метод депозита"}
                    return false
                }
                return true
            }
        }
    }
</script>

<style scoped>
    .make-deposit-container,
    .deposit-methods-container {
        border-radius: 5px;
    }

    .fs-11 {
        font-size: 11px;
    }

    .hint-text {
        color: #4d76a1;
    }

    .promo-btn {
        background-image: linear-gradient(to right, #001d56, #4c75a0);
        color: white !important;
        border-radius: 20px;
        padding: 10px 15px;
        font-size: 13px;
        box-shadow: none !important;
        border: 0;
    }

    .promo-btn:hover {
        background-image: linear-gradient(to left, #001d56, #4c75a0);
        box-shadow: 0 0 10px 0 #4c75a0!important;
    }

    .deposit-method {
        border: 2px solid transparent;
        background-color: #ccd2df;
        border-radius: 7px;
        display: flex;
    }

    .deposit-method-image {
        height: 70px;
        padding: 5px;
        width: 50%;
        object-fit: contain;
    }
    .min-amount-label{
        font-size: 13px;
        width: 50%;
        display: flex;
        justify-content: right;
        align-items: center;
    }
    .selected {
        border: 2px solid #4c75a0;
    }
</style>
